import React from 'react';
import loadable from '@loadable/component';
import { Grid, Cell } from '@trbl/react-css-grid';
import ShapedImage from '../ShapedImage';
import GoogleMap from '../GoogleMap';
import Card from '../Card';

const Form = loadable(() => import('../Form'));

const BTFContact = React.memo(({ body, blockImage, services, businessInfo }) => {
  const {
    name,
    address,
    city,
    state,
    zip,
    email,
    phoneNumber,
    displayNumber,
  } = businessInfo.childMarkdownRemark.frontmatter;
  return (
    <>
      <section id="contact--content">
        <Grid>
          <Cell
            hStart={5}
            hSpan={10}
            hStartM={5}
            hSpanM={11}
            hStartS={2}
            hSpanS={14}
          >
            {body && (
              <div
                className="wmfab__markup-content"
                dangerouslySetInnerHTML={{ __html: body }}
              />
            )}
            <address>
              <strong>{name}</strong><br/>
              {address}<br/>
              {city}, {state} {zip}<br/>
              <a href={`mailto:${email}`}>{email}</a><br/>
              <a href={`tel:+1${phoneNumber}`}>{displayNumber}</a>
            </address>
          </Cell>
        </Grid>
      </section>
      <section>
        <Grid
          id="contact--featured"
        >
          <Cell
            hSpan={16}
            id="contact--image-divider"
          >
            <ShapedImage
              color="purple"
              backgroundImage={blockImage}
              topCorner="left"
              bottomCorner="left"
            />
            <Grid
              id="contact--form-offset"
            >
              <Cell
                hStart={4}
                hSpan={13}
                hStartL={3}
                hSpanL={14}
                hStartM={2}
                hSpanM={15}
                hStartS={2}
                hSpanS={14}
                id="contact--form-container"
              >
                <Grid>
                  <Cell
                    hSpan={5}
                    hSpanM={6}
                    hSpanS={14}
                  >
                    <Form/>
                  </Cell>
                  <Cell
                    hSpan={8}
                    hSpanL={9}
                    hSpanM={9}
                    hSpanS={14}
                    id="contact--map-container"
                  >
                    <GoogleMap/>
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </section>
      <aside>
        <Grid
          id="about--service-list"
        >
          <Cell
            hStart={3}
            hSpan={12}
            hStartL={3}
            hSpanL={12}
            hStartM={3}
            hSpanM={12}
            hStartS={3}
            hSpanS={12}
            id="about--service-list-copy"
          >
            <h2>What We Do Best</h2>
          </Cell>
          <Cell
            hStart={3}
            hSpan={9}
            hSpanL={12}
            hSpanM={12}
            hStartS={3}
            hSpanS={12}
          >
            <Grid>
              {services.nodes.map(({
                childMarkdownRemark: {
                  fields: { slug },
                  frontmatter: {
                    title,
                    photo,
                  },
                },
              }) => {
                return (
                  <Cell
                    hSpan={3}
                    hSpanL={4}
                    hSpanM={4}
                    hSpanS={12}
                  >
                    <Card
                      name={title}
                      slug={`/services${slug}`}
                      image={photo}
                    />
                  </Cell>
                )
              })}
            </Grid>
          </Cell>
        </Grid>
      </aside>
    </>
  );
});

export default BTFContact;
